import React from "react";
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";

const CustomCarousel = ({ children,customResponsive, className, max }) => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 1400 },
          items: max || 5,
        },
        desktop: {
          breakpoint: { max: 1400, min: 1225 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1224, min: 769 },
          items: 3,
        },
        mobile: {
          breakpoint: { max: 768, min: 500 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 499, min: 0 },
          items: 1
        },
      };
      
  return <Carousel  className={className} responsive={customResponsive || responsive}>{children}</Carousel>;
};

export default CustomCarousel;
