export const TESTIMONIAL_DATA = [
    {
        by : 'Jake (Fremont, CA)',
        img : 'https://res.cloudinary.com/insight-byte/image/upload/v1667836614/test-1_cf0rhn.png',
        said : 'Definitely a must-stay when in socal! The views here are breathtaking, and the secured parking is a huge plus as well. Check in and check out were super easy and smooth, and the host was very responsive and attended to every question/need! Location is awesome too, and we loved our stay here!'
    },
    {
        by : 'Julian, Norwalk, CA',
        img : 'https://res.cloudinary.com/insight-byte/image/upload/v1667836612/test-2_lemmbs.png',
        said : 'We enjoyed our stay in this house. The house was nice & roomy and gave us a home away from home. The neighborhood was quiet and close to grocery stores, restaurants and freeways. We made short easy trips to the beach, Disneyland, Knotts Berry Farm, downtown LA & Dodger Stadium.'
    },
    {
        by : 'Andy, Fontana, CA',
        img : 'https://res.cloudinary.com/insight-byte/image/upload/v1667836613/test-3_h8qv5y.png',
        said : 'Beautiful house in a great location. Host replies fast for any questions you may have. I would 100 percent would recommend staying here.'
    },
   
]