import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { TESTIMONIAL_DATA } from '../../temp/testimonialsData'
import CustomCarousel from '../CustomCarousel'


const TestimonialComponent = ({name,img,desc, height,setHeight})=>{
  const ref= useRef();
  useEffect(()=>{
    setHeight(prev=>[...prev,ref.current.offsetHeight]);
  }, [ref.current])
  const [cmpHeight,setCmpHeight] = useState(350);
  useEffect(()=>{
    setCmpHeight(Math.max(...height))
  }, [height])
    return (
        <div className='w-[90%] h-auto' >
            <div style={{background : `url(${img}) center center/cover`}} className='w-full relative z-[0] translate-y-[3rem] h-[250px] rounded-[2.5rem] shadow-xl'></div>
            <div style={{minHeight : cmpHeight}} ref={ref} className='bg-white h-auto min-h-fit relative z-[2] -translate-y-[2rem] rounded-[2.5rem] shadow-xl py-8 px-8'>
                <div className='flex text-[#ffad02] items-center gap-1'>
                    <AiFillStar size={18}/>
                    <AiFillStar size={18}/>
                    <AiFillStar size={18}/>
                    <AiFillStar size={18}/>
                </div>
                <div className='mt-5 flex gap-5'>
                    <div className='text-xl font-bold'>{name}</div>
                    <div className='text-lg opacity-60'>Oct 2022</div>
                </div>
                <div className='mt-5'>
                   {desc}
                </div>
            </div>
        </div>
    )
}

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1400 },
      items:  3,
    },
    desktop: {
      breakpoint: { max: 1400, min: 1225 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1224, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 400 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1
    },
  };

const Testimonials = () => {
  const [height,setHeight] = useState([]);

  return (
    <div className='mt-16 container'>
        <div className='text-3xl font-bold'>What our guests think</div>
        <div className='text-lg mt-5 leading-5 md:leading-normal md:tracking-normal tracking-wide'>Making sure things go right and being there when they don’t—it’s what we do at Away Living Los Angeles. We offer your guests 24/7 guest service and on-site help for an effortless vacation.</div>
        <CustomCarousel className='w-full' max={3}>
           {TESTIMONIAL_DATA.map(t=><TestimonialComponent height={height} setHeight={setHeight} key={t.by} name={t.by} desc={t.said} img={t.img}/>)}
        </CustomCarousel>
    </div>
  )
}

export default Testimonials