import axios from "axios";

// const serverInstance = axios.create({
//     baseURL : 'https://awayliving-backend.herokuapp.com/'
// })
const serverInstance = axios.create({
  baseURL: "https://api.awayliving.com/",
});
export const sendEnquiryEmail = async (data, onSuccess, onError) => {
  try {
    const response = await serverInstance.post("/send-enquiry-email", data);
    if (onSuccess) onSuccess(response.data);
    return response.data;
  } catch (error) {
    if (onError) onError(error);
    console.log(error);
  }
};

export const sendRequestInfoEmail = async (data, onSuccess, onError) => {
  try {
    const response = await serverInstance.post("/send-request-email", data);
    if (onSuccess) onSuccess(response.data);
    return response.data;
  } catch (error) {
    if (onError) onError(error);
    console.log(error);
  }
};
