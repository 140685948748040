import React from 'react'
import { SERVICE_DATA } from '../../temp/servicesData'
import CustomCarousel from '../CustomCarousel'

const ServiceItem = ({img,title,description})=>{
    return (
        <div className='w-[90%]'>
            <div className='w-[150px] h-[150px] rounded-full' style={{background : `url(${img}) center center/cover`}}></div>
            <div className='mt-4 font-bold tracking-wide text-xl'>{title}</div>
            <div className='opacity-80 mt-3 leading-5'>{description}</div>
        </div>
    )
}

const Services = () => {
  return (
    <div className='container mt-16 md:mt-20'>
        <div className='text-3xl font-bold'>See what Away Living can do for you</div>
        <div className='mt-6 tracking-wide w-[80%] sm:w-full leading-7 text-lg'>Embrace the idea of more—more bookings, income, and home care. When it comes to your vacation home, we'll handle it all so that you don’t have to.</div>
        <CustomCarousel className='w-full mt-12' max={4}>
            {SERVICE_DATA?.map((s,id)=><ServiceItem {...s} key={id}/>)}
        </CustomCarousel>
    </div>
  )
}

export default Services