import React, { useState } from "react";
import Swal from "sweetalert2";
import { sendRequestInfoEmail } from "../../API";
import { useNavigate } from "react-router-dom";
export const InputField = (props) => {
  return (
    <div className="w-full">
      <input
        {...props}
        className="w-full text-black rounded-md px-4 border-[1px] py-2 focus:border-[#0009] border-[#0000003c] bg-white"
      />
    </div>
  );
};

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

const RequestForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialState);
  const handleChangeField = (e) =>
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const handleSubmit = () => {
    console.log(formValues);
    sessionStorage.setItem("loaded", 1);
    sessionStorage.removeItem("home");

    setLoading(true);
    sendRequestInfoEmail(
      formValues,
      () => {
        setFormValues(initialState);
        setLoading(false);
        navigate("/success", { replace: true });
      },
      (error) => {
        Swal.fire(
          error?.response?.data?.message || "Something went wrong",
          "",
          "error"
        );
        setLoading(false);
      }
    );
  };
  return (
    <div
      className="w-full h-[87vh] md:h-[88vh] flex items-center justify-center "
      style={{
        background: `url(https://images.unsplash.com/photo-1468413253725-0d5181091126?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) center center/cover`,
      }}
    >
      <div className="w-[70%] xl:px-16 xs:py-6 xs:px-4 xs:w-[98%] lg:w-[85%] lg:px-8 sm:px-6 sm:w-[95%] mx-auto py-12 px-20 text-white bg-[#003349] rounded-3xl">
        <div className="text-5xl font-bold text-center lg:text-4xl sm:text-3xl xs:text-2xl">
          Away Living Los Angeles
        </div>
        <div className="mt-6 xs:mt-5 text-center text-xl font-semibold md:text-lg sm:text-base xs:text-sm">
          Discover full-service vacation rental management in Los Angeles
        </div>
        <div className="mt-6 xs:mt-5 text-center w-[60%] 2xl:w-[80%] mx-auto md:w-full xs:text-sm">
          More bookings, better guest service, and higher revenue—we’ll do it
          all, so that you don’t have to. See what’s possible with Away Living
          Los Angeles.
        </div>
        <div className="mt-12 xs:mt-8 grid md:grid-cols-1 grid-cols-4 lg:gap-3 gap-5">
          <InputField
            value={formValues?.firstName}
            onChange={handleChangeField}
            name="firstName"
            placeholder="First name"
          />
          <InputField
            value={formValues?.lastName}
            onChange={handleChangeField}
            name="lastName"
            placeholder="Last name"
          />
          <InputField
            value={formValues?.email}
            onChange={handleChangeField}
            name="email"
            placeholder="Email* "
          />
          <InputField
            value={formValues?.phone}
            onChange={handleChangeField}
            name="phone"
            placeholder="Phone (optional)"
          />
        </div>
        <div className="mt-5 md:justify-center flex items-center justify-end">
          <button
            onClick={handleSubmit}
            disabled={
              !formValues.firstName ||
              !formValues.lastName ||
              !formValues.email ||
              loading
            }
            className="bg-[#ffe700] disabled:opacity-50 md:w-full px-7 py-2 rounded-md text-[#003349] font-bold"
          >
            {loading ? "Loading.." : "Request Info"}
          </button>
        </div>
        <div className="text-center text-lg xs:text-base xs:mt-5 font-bold mt-8">
          OR CONTACT US AT (510)-579-9104
        </div>
      </div>
    </div>
  );
};

export default RequestForm;
