export const modulateData = (data)=>{
    const revenue = data?.property_stats?.revenue;
    let sum = 0;
    const labels = [];
    const values = [];
    let keysRevenue = Object.keys(revenue);
    keysRevenue.forEach((key)=>{
        let entryKeys = Object.keys(revenue[key]);
        entryKeys.forEach((entryKey)=>{
            let label = `${monthNames[parseInt(entryKey)-1]} ${key}`
            let value = revenue[key][entryKey];
            labels.push(label);
            values.push(value);
            sum += value;
        })
    })
    return {sum,labels,values};
}


var monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];