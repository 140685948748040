import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/Layout";
import HelpForm from "../components/PrimaryPage/HelpForm";
import LocalHomeCare from "../components/PrimaryPage/LocalHomeCare";
import MeetTeam from "../components/PrimaryPage/MeetTeam";
import RequestForm from "../components/PrimaryPage/RequestForm";
import Services from "../components/PrimaryPage/Services";
import Stories from "../components/PrimaryPage/Stories";
import Testimonials from "../components/PrimaryPage/Testimonials";
import ThingsToDo from "../components/PrimaryPage/ThingsToDo";

const PrimaryPage = () => {
  const [loading, setLoading] = useState(sessionStorage.getItem("home"));

  const targetDivRef = useRef(null);

  useEffect(() => {
    if (loading <= 1) {
      sessionStorage.setItem("home", 2);
      setLoading(2);
      window.location.reload();
      console.log("page  loaded");
    } else {
      console.log("page already loaded");
      
      // Scroll functionality
      let locate = window.location.href.split('/').pop();
      locate = locate.replace('?', '');
      console.log("locate: ", locate);

      var checkID = locate; // Update this line to correctly extract the checkID
      console.log("checkID: ", checkID);

      if (checkID === 'contactus') {
        targetDivRef.current = document.getElementById('contact-form');
        console.log('working');
        setTimeout(() => {
          targetDivRef.current.scrollIntoView({ behavior: "smooth" }); // Use "smooth" for scrolling  
        }, 10);
        

      } else if (checkID === 'aboutus') {
        targetDivRef.current = document.getElementById('about-us');
        console.log('working about us');
        targetDivRef.current.scrollIntoView({ behavior: "smooth" }); // Use "smooth" for scrolling
      }
          
    }
  }, []);
  
  window.fbq("track", "ViewContent");
  return (
    <Layout>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=639791887906610&ev=PageView&noscript=1"
        />
      </noscript>
      <img
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://www.facebook.com/tr?id=639791887906610&ev=PageView&noscript=1"
      />

      <RequestForm />
      <MeetTeam />
      <LocalHomeCare />
      {/* <ThingsToDo/> */}
      <Services />
      <Stories />
      <Testimonials />
      <HelpForm />
    </Layout>
  );
};

export default PrimaryPage;
