import ceo from "../assets/ceo.png";
export const TEAM_DATA = [
  {
    title: "FOUNDER",
    name: "Isis Chu",
    email: " hello@awayliving.com",
    img: ceo,
  },
  {
    title: "EXECUTIVE MANAGER",
    name: " Justvelle Pilingo",
    email: "info@awayliving.com",
    img: "https://res.cloudinary.com/insight-byte/image/upload/v1667836493/user4_gjtv75.jpg",
  },
  {
    title: "Regional Cleanliness Director",
    name: "Bessie",
    email: "hello@awayliving.com",
    img: "https://res.cloudinary.com/insight-byte/image/upload/v1667836493/user3_n5ldki.png",
  },
  //     {
  //     title : 'Director Of Operations',
  //     name : 'Theresa Koren',
  //     email : 'theresa.koren@vacasa.com',
  //     img : "https://www-vacasa.imgix.net/Noelle_Myrtle_Beach.png?auto=format%2Ccompress&fit=crop&h=142&ixlib=python-3.2.1&q=45&w=132"
  // },
  //     {
  //     title : 'Director Of Operations',
  //     name : 'Theresa Koren',
  //     email : 'theresa.koren@vacasa.com',
  //     img : "https://www-vacasa.imgix.net/Noelle_Myrtle_Beach.png?auto=format%2Ccompress&fit=crop&h=142&ixlib=python-3.2.1&q=45&w=132"
  // },
  //     {
  //     title : 'Director Of Operations',
  //     name : 'Theresa Koren',
  //     email : 'theresa.koren@vacasa.com',
  //     img : "https://www-vacasa.imgix.net/Theresa_Koren_Myrtle_Beach.png?auto=format%2Ccompress&fit=crop&h=142&ixlib=python-3.2.1&q=45&w=132"
  // },
  //     {
  //     title : 'Director Of Operations',
  //     name : 'Theresa Koren',
  //     email : 'theresa.koren@vacasa.com',
  //     img : "https://www-vacasa.imgix.net/Terry_Myrtle_Beach.png?auto=format%2Ccompress&fit=crop&h=142&ixlib=python-3.2.1&q=45&w=132"
  // },
];
