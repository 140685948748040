import React from 'react'
import Layout from "../components/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
        <div className="container py-12 mt-5">
            {/* Title */}
            <h1 className="text-3xl text-[#003349] font-semibold">Privacy Policy</h1>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                At awayliving.com (hereafter referred to as "<b>we</b>", "<b>us</b>", or "<b>our</b>"), 
                we are committed to protecting and respecting the privacy of our customers and website visitors. 
                This Privacy Policy outlines how we collect, use, and protect your personal information when you 
                interact with our website, purchase products, or use our services.
            </p>
            {/* Scope */}
            <h2 className="text-xl text-[#003349] font-semibold">Scope of the Policy</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                This policy applies to all personal information collected, processed, and stored by us, including 
                information collected through our website, mobile applications, and other services. This policy 
                applies to all users of our services, including customers, partners, and website visitors.
            </p>
            {/* Definition */}
            <h2 className="text-xl text-[#003349] font-semibold">Definitions of Key Terms</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                In this policy, "<b>personal information</b>" refers to any information that can be used to identify you, 
                such as your name, email address, phone number, or payment information. "<b>Processing</b>" refers to any 
                operation performed on personal information, including collection, storage, use, and disclosure. 
                "<b>Data subject</b>" refers to any individual whose personal information is collected and processed by 
                <b>awayliving.com</b>.	
            </p>
            {/* Acknowledgement */}
            <h2 className="text-xl text-[#003349] font-semibold">Acknowledgement</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                By using our website or services, you acknowledge and agree to the terms of this privacy policy. If you do 
                not agree to the terms of this policy, please do not use our website or services.
            </p>
            {/* Information Collection */}
            <h2 className="text-xl text-[#003349] font-semibold">Information We Collect</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We collect personal information from you when you:
            </p>
            <ol className="list-disc ml-10">
                <li>Register on our website or create an account;</li>
                <li>Make a purchase;</li>
                <li>Subscribe to our newsletter;</li>
                <li>Contact our customer support;</li>
                <li>Participate in promotions or surveys; or</li>
                <li> Use any other feature on our website where you voluntarily provide personal information.</li>
            </ol>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                The types of personal information we collect may include:
            </p>
            <ul className="list-disc ml-10">
                <li>Contact information, such as your name, email address, phone number, and shipping address;</li>
                <li>Billing information, such as your credit card number, expiration date, and billing address;</li>
                <li>Account information, such as your username, password, and account preferences;</li>
                <li>Demographic information, such as your age, gender, and interests;</li>
                <li>Information related to your purchase history and product preferences; and</li>
                <li>Any other information you provide to us.</li>
            </ul>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We process your personal information on the legal basis of our legitimate interests or with your 
                consent, where required by law. We will retain your personal information for as long as 
                necessary to fulfill the purposes outlined in this policy, or as otherwise required by law.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                You have the right to access, correct, or delete your personal information at any time. Please 
                contact us at <span className="text-red-600 font-bold">{" hello@awayliving.com"}</span> to exercise 
                your data protection rights.
            </p>
            {/* Information Usage */}
            <h2 className="text-xl text-[#003349] font-semibold">How We Use Your Information</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We use your personal information for the following purposes:
            </p>
            <ul className="list-disc ml-10">
                <li>To process and fulfill your orders, including shipping and billing;</li>
                <li>To provide customer support and respond to your inquiries;</li>
                <li>To communicate with you about our products, promotions, and services;</li>
                <li>To personalize your experience and recommend products that may interest you;</li>
                <li>To analyze and improve our website, products, and services;</li>
                <li>To maintain the security and integrity of our website; and</li>
                <li>To comply with legal requirements and protect our rights.</li>
            </ul>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We may share your personal information with third-party service providers who assist us 
                with the above purposes, such as payment processors or shipping companies. We may also 
                share your personal information with law enforcement agencies, government authorities, 
                or other third parties if we believe it is necessary to comply with a legal obligation, 
                protect our rights or property, or prevent fraud or other illegal activities.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We may transfer your personal information to countries outside of your home country, 
                including to countries that may not offer the same level of data protection as your home 
                country. We will ensure that any such transfers are made in compliance with applicable 
                data protection laws and that appropriate safeguards are in place to protect your 
                personal information.
            </p>
            {/* Information Protection */}
            <h2 className="text-xl text-[#003349] font-semibold">How We Protect Your Information</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We take the security of your personal information seriously and have implemented 
                industry-standard security measures to protect it from unauthorized access, use, or 
                disclosure. These measures include encryption, firewalls, and secure socket layer 
                (SSL) technology.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                Despite our efforts to protect your personal information, no security measures are 
                perfect or impenetrable, and we cannot guarantee the security of your personal 
                information. If you believe that your personal information has been compromised, 
                please contact us immediately at 
                <span className="text-red-600 font-bold">{" hello@awayliving.com"}</span>.

            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                In the event of a data breach that affects your personal information, we will 
                notify you as required by applicable law. We will also take appropriate steps to 
                mitigate the effects of the breach and to prevent similar incidents from 
                occurring in the future.
            </p>
            {/* Information Sharing */}
            <h2 className="text-xl text-[#003349] font-semibold">Sharing Your Information</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We do not sell, rent, or trade your personal information to third parties. We 
                may share your information with trusted third parties who assist us in operating 
                our website, providing our services, or processing your transactions, such as 
                payment processors, shipping providers, and marketing partners. These third 
                parties are required to keep your information confidential and use it only for 
                the purposes for which we disclose it to them.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We may also disclose your personal information if required by law, to protect 
                our rights, or in connection with a merger, acquisition, or sale of our assets.
            </p>
            {/* Cookies & Tracking */}
            <h2 className="text-xl text-[#003349] font-semibold">Cookies and Tracking Technologies</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We use cookies and similar technologies to collect information about how you use 
                our services and to personalize your experience. Cookies are small text files 
                that are stored on your device when you visit our website. Some of the cookies we 
                use are essential for the functioning of our website, while others are used for 
                analytics, advertising, or other purposes.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We use the following types of cookies:
            </p>
            <ul className="list-disc ml-10">
                <li>
                    <b>Strictly necessary cookies:</b> These cookies are necessary for the functioning of 
                    our website and cannot be disabled. They are typically used to remember your 
                    preferences, such as your language or location.
                </li>
                <li>
                    <b>Performance cookies:</b> These cookies are used to collect information about how 
                    you use our website, such as which pages you visit or how long you spend on our
                    website. This information helps us to improve our website and to understand how users 
                    interact with our services.
                </li>
                <li>
                    <b>Functional cookies:</b> These cookies are used to remember your preferences or to 
                    personalize your experience on our website. For example, we may use cookies to 
                    remember your login information or to show you personalized content based on your 
                    browsing history.
                </li>
                <li>
                    <b>Advertising cookies:</b> These cookies are used to show you targeted ads based on 
                    your browsing history or interests. We may use these cookies to show you ads on our 
                    website or on third-party websites.
                </li>
            </ul>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We also use other similar technologies, such as web beacons or pixels, to collect 
                information about how you use our services. These technologies may be used in emails or 
                on our website to track how you interact with our content.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                You can manage your cookie preferences by adjusting your browser settings or by using our 
                cookie management tool. Please note that disabling cookies may affect the functioning of 
                our website or limit your access to certain features.
            </p>
            {/* Third-Party Links */}
            <h2 className="text-xl text-[#003349] font-semibold">Third-Party Links</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                Our website may contain links to third-party websites. We are not responsible for the 
                privacy practices of these websites, and this Privacy Policy does not apply to them. We 
                encourage you to review the privacy policies of any third-party websites you visit.
            </p>
            {/* Children's Privacy */}
            <h2 className="text-xl text-[#003349] font-semibold">Children's Privacy</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                Our services are not intended for use by children under the age of 13, and we do not 
                knowingly collect personal information from children under this age. If you are a parent 
                or guardian and you believe that your child has provided us with personal information, 
                please contact us immediately at  
                <span className="text-red-600 font-bold">{" hello@awayliving.com"}</span>, and we will take appropriate 
                steps to remove the information from our systems.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We may collect personal information from children under the age of 13 with the consent of their parent 
                or guardian. In such cases, we will collect only the minimum amount of personal information necessary to 
                provide our services, and we will obtain verifiable parental consent before collecting the information. 
                We will also provide parents with access to their child's personal information and the ability to request 
                that the information be deleted.		
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                If you are a child under the age of 13 and you would like to use our services, please ask your parent or 
                guardian for permission and have them contact us to provide their consent.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We may use age verification techniques, such as asking for a birthdate or other information, to ensure 
                that our services are not being used by children under the age of 13.
            </p>
            {/* Your Rights */}
            <h2 className="text-xl text-[#003349] font-semibold">Your Rights and Choices</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                You have the following rights and choices regarding your personal information:
            </p>
            <ul className="list-disc ml-10">
                <li>
                    <b>Access and Update:</b> You can review, update, or delete your personal information by logging 
                    into your account on our website or by contacting our customer support.
                </li>
                <li>
                    <b>Unsubscribe:</b> You can unsubscribe from our marketing emails by clicking the "unsubscribe" 
                    link at the bottom of any such email or by contacting our customer support.
                </li>
                <li>
                    <b>Opt-Out of Targeted Advertising:</b> You can opt-out of targeted advertising by adjusting 
                    your browser settings or by visiting the Network Advertising Initiative Opt-Out page.
                </li>
            </ul>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                Please note that we may still send you non-promotional emails, such as order confirmations, 
                shipping notifications, or customer service communications.
            </p>
            {/* Changes */}
            <h2 className="text-xl text-[#003349] font-semibold">Changes to This Privacy Policy</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We may update this privacy policy from time to time to reflect changes in our practices or to 
                comply with new laws or regulations. We will notify you of any material changes to the privacy 
                policy by posting the updated policy on our website or by sending you an email or other 
                communication.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                You should review this privacy policy regularly to stay informed about our practices and any 
                changes to the policy. By continuing to use our services after any changes to the policy, you 
                agree to the updated terms.
            </p>
            {/* Contact */}
            <h2 className="text-xl text-[#003349] font-semibold">Contact Us</h2>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                We are committed to protecting your privacy and to complying with all applicable data protection 
                laws and regulations. If you have any questions or concerns, please do not hesitate to contact us.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                Thank you for using our services.
            </p>
            <p className="my-6 tracking-wide w-[80%] sm:w-full leading-7 text-md">
                www.awayliving.com <br/>
                Email: hello@awayliving.com 
            </p>
        </div>
    </Layout>
  )
}

export default PrivacyPolicy