import React from "react";
import HomeImg from '../../assets/house.png';
import LivingRoomImg from '../../assets/livingRoom.png';
import Form from "./Form";
const ProjectManagement = () => {
  return (
    <div id='projectmanagement' className="mt-6">
      <div className="container grid items-center md:grid-cols-1 md:w-full md:h-auto grid-cols-2 h-[500px] mxl:h-[450px] gap-10">
        <div>
            <div className="text-4xl font-bold  md:text-3xl sm:text-2xl">See how much your home could make you..</div>
            <div className="mt-4 text-lg md:text-base">Discover A-Z vacation rental property management</div>
            <div className="flex mt-4 gap-5 items-center md:gap-2">
              <Form/>
            </div>
        </div>
        <div className="w-full h-full md:h-[400px] sm:h-[350px] xs:h-[300px]" style={{background :`url(${HomeImg}) center center/cover`}}></div>
      </div>
      <div className="mt-7 md:mt-12 container grid md:grid-cols-1 md:h-auto items-center  mxl:h-[450px] grid-cols-2 h-[500px] gap-10">
        <div className="w-full h-full md:h-[400px] sm:h-[350px] xs:h-[300px] md:order-2" style={{background : `url(${LivingRoomImg}) center center/cover`}}></div>
        <div className="md:order-1">
          <div className="text-3xl font-[500] md:text-2xl">Get the Most out of Your Property</div>
          <div className="mt-5 text-lg md:text-base">Make money while you're away. AwayLiving Management is the first completely hands-off approach to managing your vacation home. We use our proprietary tech suite for bookings, operationalization in field management, and an elevated guest experience that maximizes income - it's as simple as clicking one button on our website or app (or reaching out via phone).</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectManagement;
