import React, { useRef } from "react";
import { Link } from "react-router-dom"
import Logo from '../assets/logo.svg';

const Footer = () => {

  // Create a ref to the target div element
  const targetDivRef = useRef(null);

  // Function to handle the onClick event
  const scrollToDiv = (id) => {
    // Scroll to the target div element
    console.log('function working')
      targetDivRef.current = document.getElementById(id)
      if(targetDivRef.current) {
        targetDivRef.current.scrollIntoView({ behavior: "smooth" });
      }
          
  };
  return (
    <div className="flex border-b-[1px] items-center justify-between w-full sm:px-4 px-8 py-4">
      <div>
        <a href="/">
          <img src={Logo} className="w-[100px]" />
        </a>
      </div>
      <div className="flex xs:flex-col xs:gap-1 mt-1 items-center gap-6">
        <div>
          <a href="/#projectmanagement">Project Management</a>
        </div>
        <div>
        {(window.location.href.split('/').pop().replace('?', '') == 'aboutus') ?
        <div><button onClick={scrollToDiv('about-us')}>About Us</button></div>
        : 
        <div><Link to='/?aboutus'>About Us</Link></div>
        }
        </div>
        <div>
        {(window.location.href.split('/').pop().replace('?', '') == 'contactus') ?
        <div><button onClick={scrollToDiv('contact-form')}>Contact Us</button></div>
        :
        <div><Link to='/?contactus'>Contact Us</Link></div>
        }
        </div>
        <div>
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
