export const SERVICE_DATA = [
    {
        title : 'Profit Maximization',
        description : "It's a fact that you can earn 20% more revenue with the correct bonus. We do our best to ensure all of our clients get what they deserve and work together for success!",
        img : 'https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
    },
    {
        title : 'Weekly Maintenance ',
        description : "Our talented repair and cleaning network work with us to fix up your house; you'll never notice anything ever broken!",
        img : 'https://cms-assets.boise.vacasa.com/img/local_pages/linen-service.cc1c17e0100b.jpg'
    },
    {
        title : '24/7 Guest Support',
        description : "Even while you're comfortably sleeping, our hospitable guest support always responds to all potential guests. Within the hour.",
        img : 'https://cms-assets.boise.vacasa.com/img/local_pages/marketing-service.b92de9b817fe.jpg'
    },
    {
        title : 'Direct Payouts',
        description : 'With direct payouts monthly to your bank account, there is no hassle or second thought, only money deposited into your provided account information!',
        img : 'https://images.unsplash.com/photo-1529290130-4ca3753253ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80'
    },
    {
        title : 'Supportive Onboarding',
        description : 'Instant communication with managers available at all times with video chat support.',
        img : 'https://images.unsplash.com/photo-1455587734955-081b22074882?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
    },
    {
        title : 'A-Z Property Management',
        description : 'Streamlined furnishing, purchase & assembly team with repairs within week. Short term rental property identification with inspection before & after each guest.',
        img : 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
    },
    {
        title : '5 Star Guest Experience',
        description : 'Adaptable personal guest requests with 24/7 communication. Self check in entry & clean/fresh welcome every time.',
        img : 'https://cms-assets.boise.vacasa.com/img/local_pages/assurant-service.2b9b678bde68.jpg'
    },
    {
        title : 'Homeowner Care',
        description : 'Availability of services like monthly direct payouts, annual permit renewal, security camera access, keypad pin locks & utility maintainence.',
        img : 'https://images.unsplash.com/photo-1592229505726-ca121723b8ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80'
    },
]