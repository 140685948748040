import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const Success = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(sessionStorage.getItem("loaded"));
  useEffect(() => {
    if (loading <= 1) {
      sessionStorage.setItem("loaded", 2);
      setLoading(2);
      window.location.reload();
      console.log("page is loading", loading);
    } else {
      Swal.fire(
        "Your request has been created successfully, we will contact you soon!",
        "",
        "success"
      ).then((ar) => {
        sessionStorage.removeItem("loaded");
        sessionStorage.setItem("home", 1);
        navigate("/", { replace: true });
      });
      console.log("page already loaded");
    }

    window.fbq("track", "Lead");
  }, []);

  return (
    <>
      <div>
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=639791887906610&ev=Lead&noscript=1"
          />
        </noscript>

        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=639791887906610&ev=Lead&noscript=1"
        />
      </div>
    </>
  );
};

export default Success;
