import React, { useState } from "react";
import Swal from "sweetalert2";
import { sendRequestInfoEmail } from "../../API";
import { InputField } from "./RequestForm";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

const HelpForm = () => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);
  const handleChangeField = (e) =>
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const handleSubmit = () => {
    console.log(formValues);
    setLoading(true);

    sendRequestInfoEmail(
      formValues,
      () => {
        // Swal.fire(
        //   "Your request has been created successfully, we will contact you soon!",
        //   "",
        //   "success"
        // );
        setFormValues(initialState);
        setLoading(false);
        setSubmitted(true);
      },
      (error) => {
        Swal.fire(
          error?.response?.data?.message || "Something went wrong",
          "",
          "error"
        );
        setLoading(false);
      }
    );
  };
  return (
    <div
      style={{
        background: `url(https://images.unsplash.com/photo-1510414842594-a61c69b5ae57?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) center center/cover`,
      }}
      className="mt-20 py-32 flex items-center justify-center"
      id="contact-form"
    >
      <div className="w-[60%] xl:w-[70%] xl:px-10 mxl:px-8 xs:px-3 xs:py-7 sm:w-[93%] xs:w-[96%] mxl:w-[75%] lg:w-[85%]  mx-auto px-16 py-10 bg-white rounded-[2.5rem]">
        <div className="text-3xl text-center font-bold sm:text-2xl xs:text-xl">
          Away Living Los Angeles is here to help
        </div>
        <div className="mt-3 text-xl text-center font-bold sm:font-semibold xs:text-base">
          Let's get Started
        </div>
        {submitted?<div className="mt-8">
          <div className="text-2xl md:text-xl sm:text-lg text-center  font-semibold">Thanks! We'll be in touch soon with the information.</div>
        </div>:<><div className="mt-8 grid grid-cols-4 gap-5 md:grid-cols-1">
          <InputField
            value={formValues?.firstName}
            onChange={handleChangeField}
            name="firstName"
            placeholder="First name"
          />
          <InputField
            value={formValues?.lastName}
            onChange={handleChangeField}
            name="lastName"
            placeholder="Last name"
          />
          <InputField
            value={formValues?.email}
            onChange={handleChangeField}
            name="email"
            placeholder="Email* "
          />
          <InputField
            value={formValues?.phone}
            onChange={handleChangeField}
            name="phone"
            placeholder="Phone (optional)"
          />
        </div>
        <div className="mt-5 md:w-full flex items-center justify-end">
          <button
            onClick={handleSubmit}
            disabled={
              !formValues.firstName ||
              !formValues.lastName ||
              !formValues.email ||
              loading
            }
            className="bg-[#ffe700] disabled:opacity-50 md:w-full px-7 py-2 rounded-md text-[#003349] font-bold"
          >
            {loading ? "Loading.." : "Request Info"}
          </button>
        </div></>}
        <div className="text-center text-lg xs:text-sm font-bold mt-8">
          OR CONTACT US AT (510)-579-9104
        </div>
      </div>
    </div>
  );
};

export default HelpForm;
