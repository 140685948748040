import React, { useEffect, useState, useRef } from 'react'
import { Link } from "react-router-dom"
import Cookies from 'js-cookie'
import { TbMenu2 } from 'react-icons/tb';
import gsap from 'gsap';
import Logo from '../assets/logo.svg';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const showMenu = ()=>{
    const tl = gsap.timeline();
    tl.from('.menu-hamburger', {
      duration : 0,
      css : {height : 0, overflow : 'hidden'}
    }).to('.menu-hamburger', {
      duration : 0.8,
      css :{height : 'auto'},
      ease : 'Power4.easeOut'
    })
  }
  const location = useLocation();
  // console.log(location)
  const hideMenu = ()=>{
    const tl = gsap.timeline();
    tl.from('.menu-hamburger', {
      duration : 0,
      css : {height : 'auto', overflow : 'hidden'}
    }).to('.menu-hamburger', {
      duration : 0.8,
      css :{height : '0'},
      ease : 'Power4.easeOut'
    })
  }

  useEffect(()=>{
    hideMenu();
  }, [location])
  
  const [isHamburger, setIsHamburger] = useState(false);

  useEffect(()=>{
    if (isHamburger) {
      showMenu();
    }
    else {
      hideMenu();
    }
  }, [isHamburger])

  
  // Create a ref to the target div element
  const targetDivRef = useRef(null);

  // Function to handle the onClick event
  const scrollToDiv = (id) => {
    // Scroll to the target div element
    console.log('function working')
      targetDivRef.current = document.getElementById(id)
      if(targetDivRef.current) {
        targetDivRef.current.scrollIntoView({ behavior: "smooth" });
      }
          
  };

  return (
    <div className='flex border-b-[1px] items-center justify-between w-full sm:px-4 px-8 py-4'>
      <div><a href='/'><img src={Logo} className='w-[100px] sm:w-[80px]'/></a></div>
      <div className='flex items-center gap-6 md:hidden'>
        <div><a href='/#projectmanagement'>Project Management</a></div>
        {(window.location.href.split('/').pop().replace('?', '') == 'aboutus') ?
        <div><button onClick={scrollToDiv('about-us')}>About Us</button></div>
        : 
        <div><Link to='/?aboutus'>About Us</Link></div>
        }
        {(window.location.href.split('/').pop().replace('?', '') == 'contactus') ?
        <div><button onClick={scrollToDiv('contact-form')}>Contact Us</button></div>
        :
        <div><Link to='/?contactus'>Contact Us</Link></div>
        }
        <div><Link to='/privacy-policy'>Privacy Policy</Link></div>
      </div>
      <div onClick={()=>setIsHamburger(prev=>!prev)} className='hidden md:block px-3 py-1 border-[1px] rounded-md'><TbMenu2 size={38}/></div>
      <div className='absolute menu-hamburger top-[80px] h-0 overflow-hidden w-full left-0  z-[100000] bg-white'>
        <div className='py-3 pt-8 px-3 text-lg font-[500]'><a href='/#projectmanagement'>Project Management</a></div>
        <div className='py-3 px-3 text-lg font-[500]'>
        {(window.location.href.split('/').pop().replace('?', '') == 'aboutus') ?
        <div><button onClick={scrollToDiv('about-us')}>About Us</button></div>
        : 
        <div><Link to='/?aboutus'>About Us</Link></div>
        }
        </div>
        <div className='py-3 px-3 text-lg font-[500]'>
        {(window.location.href.split('/').pop().replace('?', '') == 'contactus') ?
        <div><button onClick={scrollToDiv('contact-form')}>Contact Us</button></div>
        :
        <div><Link to='/?contactus'>Contact Us</Link></div>
        }
        </div>
        <div className='py-3 px-3 pb-7 text-lg font-[500]'><a href='/privacy-policy'>Privacy Policy</a></div>
      </div>
    </div>
  )
}

export default Header