import React from "react";
import NetworkIcon from "../../assets/networkIcon.png";
import EmailIcon from "../../assets/emailIcon.png";
import DollarIcon from "../../assets/dollarIcon.png";
import SettingIcon from "../../assets/settingIcon.png";
import {AiOutlineCheck} from 'react-icons/ai';
import WorkingTogether from '../../assets/workingTogether.png';
const AboutUsComponent = ({ icon, title, description, widthClass }) => {
  return (
    <div className="text-white sm:flex sm:flex-col sm:items-center">
      <div className="w-[50px] overflow-hidden">
        <img src={icon} className={`${widthClass ? widthClass : "w-[50px]"}`} />
      </div>
      <div className="text-2xl mt-2 font-semibold">{title}</div>
      <div className="mt-3">{description}</div>
    </div>
  );
};

const AboutList = ({title,list})=>{
    return (
        <div>
            <div className="text-lg font-bold">{title}</div>
            <div className="mt-4 space-y-4">
                {list.map((l,idx)=>(
                    <div className="flex items-center gap-3">
                        <AiOutlineCheck className="shrink-0" size={16}/> {l}
                    </div>
                ))}
            </div>
        </div>
    )
}

const AboutUs = () => {
  return (
    <div id='aboutus' className="container mt-8">
      <div className="py-7 px-8 text-white md:px-6 sm:py-4  sm:px-4  xs:px-3 bg-[#6c757d] ">
        <div className="text-center text-4xl xs:text-2xl sm:text-3xl font-bold">
          Generate More Wealth from your Rental
        </div>
        <div className="text-center mt-3">
          Get a free 12 month revenue projection and see if you qualify
        </div>
        <div className="mt-16 xs:mt-10 items-center md:grid-cols-1 gap-12 grid grid-cols-[1.5fr_1fr]">
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-1 sm:gap-y-12">
            <AboutUsComponent
              title="Profit Maximization"
              icon={NetworkIcon}
              description="It's a fact that you can earn 20% more revenue with the correct bonus. We do our best to ensure all of our clients get what they deserve and work together for success!"
            />
            <AboutUsComponent
              title="Weekly Maintenance"
              icon={SettingIcon}
              description="Our talented repair and cleaning network work with us to fix up your house; you'll never notice anything ever broken!"
            />
            <AboutUsComponent
              title="24/7 Guest Support"
              icon={EmailIcon}
              description="Even while you're comfortably sleeping, our hospitable guest support always responds to all potential guests. Within the hour."
            />
            <AboutUsComponent
              title="Direct Payouts"
              widthClass="w-[28px]"
              icon={DollarIcon}
              description="With direct payouts monthly to your bank account, there is no hassle or second thought, only money deposited into your provided account information!"
            />
          </div>
          <div className="w-full h-[300px]" style={{background : `url(${WorkingTogether}) center center/cover`}}></div>
        </div>
      </div>
      <div className="mt-16">
        <div className="text-center text-4xl font-bold md:text-3xl sm:text-2xl w-full">What You Get with AwayLiving</div>
        <div className="mt-3 text-center">AwayLiving is a way for YOU to live, while AwayLiving property management generates you supplemental income each month.</div>
        <div className="mt-9 grid grid-cols-4  md:grid-cols-2 sm:gap-10 sm:grid-cols-1 gap-8">
            <AboutList title='Supportive Onboarding' list={['Instant communication with managers available at all times', 'HD Real Estate Photography', 'Done for you permit process', 'Never deal with repairs again', 'Video Chat Support']}/>
            <AboutList title='A-Z Property Management' list={['Streamlined furnishing, purchase & assembly team', 'Repairs within the week', 'Dynamic optimized pricing', 'Inspections before and after each guest', 'Short term rental property identification']}/>
            <AboutList title='5 Star Guest Experience' list={['24/7 Guest Communications', 'Adaptable personal guest requests', 'Clean/fresh welcome every time', 'Self check in entry', 'Best-in-class optimized listing']}/>
            <AboutList title='Homeowner Care' list={['MONTHLY PAYOUTS. DIRECT', 'Annual permit renewal', 'Security Camera Access', 'Keypad pin Locks', 'Utility maintainence']}/>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
