import React from 'react'
import { TEAM_DATA } from '../../temp/teamData';
import CustomCarousel from '../CustomCarousel'



const TeamComponent = ({name,title,img,email})=>{
    return (
        <div className='text-[#003349] xs:flex xs:items-center xs:justify-center xs:flex-col'>
            <div style={{background : `url(${img}) center center/cover`}} className='w-[120px] rounded-md h-[120px] border-l-[5px] border-[#003349]'></div>
            <div className='mt-5 font-bold text-xl'>{name}</div>
            <div className='mt-2 font-semibold uppercase'>{title}</div>
            <div className='mt-6 w-fit py-3 pb-6 px-4 xs:w-full shadow-xl bg-[#fafbfb] rounded-lg'>
                <div className='font-semibold uppercase text-[#d0d0d0]'>CONTACT</div>
                <div className='font-semibold mt-2  text-[#414141]'>{email}</div>
            </div>
        </div>
    )
}

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1400 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1400, min: 1225 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1224, min: 700 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 700, min: 400 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1
    },
  };

const MeetTeam = () => {
  return (
    <div className='container py-12 mt-5' id="about-us">
        <div className='text-3xl text-[#003349] font-semibold'>Meet your Away Living team</div>
        <CustomCarousel customResponsive={responsive} className='w-full mt-12'>
           {TEAM_DATA?.map((d,idx)=><TeamComponent {...d} key={idx}/>)}
        </CustomCarousel>
    </div>
  )
}

export default MeetTeam