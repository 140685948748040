import { Box, CircularProgress, Modal } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { getAPI } from "../../temp/getAPI";
import ResultGraph from "./ResultGraph";
import ErrorImg from "../../assets/error.svg";
import { modulateData } from "../../temp/modulateData";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "w-fit",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,

};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const ErrorState = ({error, handleClose}) => {
  return (
    <div className="min-h-[400px] h-full w-full flex flex-col items-center justify-center">
      <div>
        <img src={ErrorImg} className="w-[300px]" />
      </div>
      <div className="text-lg font-semibold text-red-500 mt-3">
        {error || 'Something went wrong!'}
      </div>
      <div>
        <button onClick={handleClose} className="bg-blue-500 px-6 mt-3 text-white rounded-md py-2">
          Go Back
        </button>
      </div>
    </div>
  );
};

const LoadingState = () => {
  return (
    <div className="min-h-[400px] h-full w-full flex flex-col items-center justify-center">
      <div>
        <CircularProgress size={25} />
      </div>
      <div className="mt-3 text-lg font-semibold animate-pulse">loading...</div>
    </div>
  );
};

const ResultModal = ({ open, handleClose, children, formValues: data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const getResult = async () => {

    try {
     
      const res = await axios.get(
        getAPI(data.address, data.lat, data.lng, data.bedrooms, data.bathrooms)
      );
      console.log(res, "API RESULT");
      if (res?.data?.error?.message) {
        return setError(res.data.error?.message);
      }
      const modulated = modulateData(res.data);
      console.log(modulated);
      setResult(modulated);
    } catch (err) {
      console.log(err);
      setError(`Something went wrong!`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.address && data?.lat && data?.lng && data?.bedrooms && data?.bathrooms && isLoading && open) {
      console.log(`Fetching result...`);
    getResult();
    }else {
      // console.log(`Some fields are missing`);
    }
    if (!open) {
      setResult(null);
      setIsLoading(true);
      setError(false);
    }
  }, [data,open]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="w-[60vw] mxl:w-[70vw] lg:w-[80vw] sm:w-[97vw] md:w-[90vw] max-w-[900px] sm:px-2 py-4 px-4">
          {error ? (
            <ErrorState handleClose={handleClose} error={error}></ErrorState>
          ) : isLoading ? (
            <LoadingState></LoadingState>
          ) : (
            <>
              <div className="border-b-[1px] xs:py-1 py-2">
                <div className="text-center text-2xl xs:text-xl font-semibold">
                  Estimated Monthly Income
                </div>
                <div className="text-center font-semibold text-xl mt-3">
                  $ {numberWithCommas(result?.sum?.toFixed(0))} per year
                </div>
              </div>
              <div className="mt-4 flex justify-center items-center gap-2">
                <MdLocationPin /> {data?.address}
              </div>
              <div className="mt-7 w-full h-[65vh] sm:h-[60vh] xs:h-[58vh]">
                <ResultGraph labels={result?.labels} values={result?.values} />
              </div>
              <div className="w-full flex items-center mt-4 rounded-md justify-end">
                <button onClick={handleClose} className="bg-blue-500 text-white py-2 rounded-md px-8">Close</button>
              </div>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ResultModal;
