import React from 'react'
import Layout from '../components/Layout'
import AboutUs from '../components/SecondaryPage/AboutUs'
import Contact from '../components/SecondaryPage/Contact'
import ProjectManagement from '../components/SecondaryPage/ProjectManagement'

const SecondaryPage = () => {
  return (
    <Layout>
        <ProjectManagement/>
        <AboutUs/>
        <Contact/>
    </Layout>
  )
}

export default SecondaryPage