import axios from "axios";
import React, { useEffect, useState } from "react";
import { getAPI } from "../../temp/getAPI";
import LocationSearch from "../LocationSearch";
import ResultModal from "./ResultModal";
import Select from 'react-select';
import { sendEnquiryEmail } from "../../API";
import Swal from "sweetalert2";

const options = [
  {value : 'Air conditining', label : 'Air conditioning'},
  {value : 'gym', label : 'Gym'},
  {value : 'hot tub', label : 'Hot Tub'},
  {value : 'pet friendly', label : 'Pet Friendly'},
  {value : 'swimming pool', label : 'Swimming Pool'},
  {value : 'lake front', label : 'Lake Front'},
  {value : 'ocean view', label : 'Ocean View'},
  {value : 'ocean front', label : 'Ocean Front'},
  {value : 'Ski in/out', label : 'Ski in/out'},
]

const Input = (props) => {
  return (
    <div className="w-full">
      <input
        {...props}
        className="w-full px-3 text-black py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
      />
    </div>
  );
};



const TwoField = ({ children }) => {
  return (
    <div className="grid md:grid-cols-1 grid-cols-2 gap-4 w-full">
      {children}
    </div>
  );
};

const ThreeFields = ({ children }) => {
  return (
    <div className="grid md:grid-cols-1 grid-cols-3 gap-3 w-full">
      {children}
    </div>
  );
};

const initialState = {
    address: "",
    lat: "",
    lng: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    bedrooms: "",
    bathrooms: "",
    amenities: [],
  }

const Form = () => {
  const [formState, setFormState] = useState(0);
  const [formValues, setFormValues] = useState(initialState);
  const [loading,setLoading] = useState(false);
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

//   useEffect(() => {
//     console.log(formValues);
//   }, [formValues]);
const handleFormSubmit = async(e)=>{
  e.preventDefault();
  console.log(formValues);
  setLoading(true);
  sendEnquiryEmail(formValues, ()=>{
    setFormState((prev) => prev + 1)
    setLoading(false);
  }, (error)=>{
    Swal.fire(error?.response?.data?.message || "Something went wrong");
    setLoading(false);
    setFormState(0);
  })
}
  return (
    <div className="w-full">
      <ResultModal formValues={formValues} open={formState == 3} handleClose={() => {setFormState(0); setFormValues(initialState)}} />
      {formState === 0 && (
        <form onSubmit={(e)=>{e.preventDefault(); setFormState((prev) => prev + 1)}} className="w-full">
          <div className="flex items-center w-full gap-5 md:gap-2">
            <div className="w-full">
              <LocationSearch
                formState={formValues}
                setFormState={setFormValues}
              />
            </div>
            <button
              disabled={
                !formValues.address || !formValues.lat || !formValues.lng
              }
              className="px-6 disabled:opacity-60 py-2 bg-blue-500 sm:px-3 rounded-md text-white"
              type='submit'
            >
              Submit
            </button>
          </div>
          {formValues?.address&&<div className="mt-2 text-sm text-blue-600"><span className="font-bold">Selected : </span>{formValues?.address}</div>}
        </form>
      )}
      {formState === 1 && (
        <form onSubmit={(e)=>{e.preventDefault();setFormState((prev) => prev + 1) }} className="w-full">
          <TwoField>
            <Input
            required={true}
              type="number"
              name="bedrooms"
              min={0}
              max={50}
              value={formValues.bedrooms}
              onChange={onChange}
              placeholder="Bedrooms"
            />
            <Input
              type="number"
              required={true}
              min={0}
              max={50}
              name="bathrooms"
              value={formValues.bathrooms}
              onChange={onChange}
              placeholder="Bathrooms"
            />
          </TwoField>
          <div className="mt-4 w-full">
            <Select styles={{input : (provided,state) =>({...provided, paddingTop : 7})}} isMulti onChange={e=>setFormValues(prev=>({...prev,amenities : e.map(v=>v.value).join(',')}))} options={options} placeholder='Amenities'/>
          </div>
          <div className="mt-5 flex justify-end px-2">
            <button
              disabled={(!formValues.bedrooms || !formValues.bathrooms)}
              type='submit'
              className="bg-blue-500 disabled:opacity-60 px-6 text-white rounded-md py-2"
            >
              Continue
            </button>
          </div>
        </form>
      )}
      {formState === 2 && (
        <form onSubmit={handleFormSubmit} className="w-full">
          <TwoField>
            <Input
            required={true}
              name="firstName"
              value={formValues.firstName}
              onChange={onChange}
              placeholder="First Name"
            />
            <Input
              name="lastName"
              required={true}
              value={formValues.lastName}
              onChange={onChange}
              placeholder="Last Name"
            />
          </TwoField>
          <div className="mt-4"></div>
          <TwoField>
            <Input
              name="email"
              type='email'
              required={true}
              value={formValues.email}
              onChange={onChange}
              placeholder="Email Address"
            />
            <Input
              name="phone"
              type='tel'
              required={true}
              value={formValues.phone}
              onChange={onChange}
              placeholder="Phone Number"
            />
          </TwoField>
          <div className="mt-5 flex justify-end px-2">
            <button
              className="bg-blue-500 disabled:opacity-60 px-6 text-white rounded-md py-2"
              type='submit'
              disabled={
                !formValues.email ||
                !formValues.phone ||
                !formValues.firstName ||
                !formValues.lastName || loading
              }
            >
              {loading?'Loading..':'Submit'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Form;
