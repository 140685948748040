import React from "react";
import DinningRoomImg from "../../assets/dinningRoom.png";
const Contact = () => {
  return (
    <div id='contactus' className="grid container h-[500px] grid-cols-2 md:h-[400px] md:grid-cols-1 mt-12">
      <div
        className="w-full h-full"
        style={{ background: `url(${DinningRoomImg}) center center/cover` }}
      ></div>
      <div className="bg-[#6c757d] text-white sm:text-center  px-3 flex items-center w-full h-full">
        <div>
        Call us at  <a className="underline text-inherit px-3 cursor-pointer font-bold">(510)-579-9104</a> to maximize your property and revenue.
        </div>
      </div>
    </div>
  );
};

export default Contact;
