import React from 'react'


const Item = ({img,title,sub,by})=>{
    return (
        <div className='grid xs:px-3 px-10 sm:px-4 md:px-6 w-full lg:grid-cols-1 grid-cols-[1fr_2.5fr]'>
            <div style={{background : `url(${img}) center center/cover`}} className='rounded-[2.5rem] lg:h-[300px] lg:translate-y-[3.5rem] lg:translate-x-0 translate-x-[3rem] relative z-[0] w-full h-full'></div>
            <div className='bg-[#557263] lg:-translate-y-[1.5rem] lg:translate-x-0 lg:py-24 md:px-10 md:py-16 sm:px-8 sm:py-10  text-white py-36 px-20 rounded-[2.5rem] -translate-x-[3rem] relative z-[2]'>
                <div className='text-4xl font-bold md:text-3xl sm:text-2xl'>{title}</div>
                <div className='mt-8 font-semibold text-2xl sm:text-lg'>{sub}</div>
                <div className='mt-3'>{by}</div>
            </div>
        </div>
    )
}

const Stories = () => {
  return (
    <div className='space-y-20 mt-16 lg:space-y-10 md:space-y-2'>
        <Item by='—Sharon and Pierre, Away Living homeowner in Los Angeles' sub='"They do everything for us so that we don’t have to."' title='This duo is forging their financial future, with Away Living by their side' img="https://images.pexels.com/photos/254069/pexels-photo-254069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"/>
        <Item by='—Kelvin, Away Living homeowner in North Los Angeles' sub='"Regina is my local contact. She’s remarkable—she’s a rockstar."' title='How one homeowner discovered a vacation rental manager who’d treat him like family' img="https://images.unsplash.com/photo-1590074251929-76f7c465a3bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"/>
    </div>
  )
}

export default Stories