import React from 'react'
import HomeIcon from '../../assets/primary/house.webp'
import HouseIcon from '../../assets/primary/house2.webp'
import PersonIcon from '../../assets/primary/person.webp'
import CleanIcon from '../../assets/primary/clean.webp'


const Item = ({icon,title,desc})=>(
    <div className='flex gap-5 md:flex-col md:items-center md:text-center'>
        <div className='shrink-0'><img src={icon} className='w-[50px]'/></div>
        <div className=''>
            <div className='text-2xl font-bold'>{title}</div>
            <div className='mt-3'>{desc}</div>
        </div>
    </div>
);

const LocalHomeCare = () => {
  return (
    <div className='px-10 xs:px-3 mt-12 mxl:px-8 lg:px-6 lg:mt-4 xl:grid-cols-[1.4fr_1fr] lg:grid-cols-1 grid grid-cols-[1.1fr_1fr]'>
        <div className='p-16 xl:p-12 mxl:p-8 relative z-[2] lg:order-2 lg:-translate-y-[3rem] translate-x-[2.5rem] lg:translate-x-0 bg-[#007f90] rounded-[2.5rem]  text-white'>
            <div className='text-3xl font-bold md:text-center'>Dedicated local home care</div>
            <div className='mt-8 gap-12 grid grid-cols-2 xl:gap-8 md:grid-cols-1 md:gap-y-10'>
                <Item icon={HomeIcon} title='Away Living knows Los Angeles' desc='The trusted manager for homeowners of over 10 vacation rentals in the area.'/>
                <Item icon={HouseIcon} title='Satisfied homeowners' desc='Our homeowners are happy with Away Living’s services in Los Angeles, with 71% of owners as promoters of Away Living.'/>
                <Item icon={PersonIcon} title='Local expertise' desc='Your vacation home gets the attention it deserves with over 30 local operation team members in Los Angeles.'/>
                <Item icon={CleanIcon} title='Excellent cleaning' desc='Our Los Angeles vacation homes receive an impressive average cleaning score with 95% providing a score of at least 4 out of 5.'/>
            </div>
        </div>
        <div className='w-full lg:translate-x-0 lg:h-[400px] lg:translate-y-[3rem] md:h-[300px] lg:order-1 relative z-[0] -translate-x-[2.5rem] rounded-[2.5rem] h-full' style={{background : `url(https://www-vacasa.imgix.net/myrtle_beach_2.jpg?auto=format%2Ccompress&amp;fit=crop&amp;h=494&amp;ixlib=python-3.2.1&amp;q=45&amp;w=694) center center/cover`}}></div>
    </div>
  )
}

export default LocalHomeCare