import React from "react";
import PrimaryPage from "./Pages/PrimaryPage";
import SecondaryPage from "./Pages/SecondaryPage";
import { createBrowserRouter } from "react-router-dom";
import Success from "./Pages/success";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <PrimaryPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "/secondary",
    element: <SecondaryPage />,
  },
  {
    path: "/success",
    element: <Success />,
  },
]);

export default AppRouter;
